import { messages } from "@core/mixins/validation/messages";
import helpers from "@/helpers";

export const validation = {
  mixins: [messages],

  data() {
    return {
      formValidationErrors: {}
    }
  },
  methods: {
    strValidate(rules) {
      const input = rules.input ? rules.input : '';
      const message = rules.message ? rules.message : '';
      const count = rules.count ? rules.count : 0;
      const field = rules.field ? rules.field : '';

      if(!input || input === '') {
        this.formValidationErrors[field] = message;

        return false;
      } else {
        delete this.formValidationErrors[field];
      }

      if(count && count > 0) {
        if(input.length < count) {
          this.formValidationErrors[field] = this.strCount(count);

          return false;
        }
      }
    },

    emailValidate(email) {
      let re = /\S+@\S+\.\S+/;

      if(! email || !re.test(email)) {
        this.formValidationErrors['email'] = this.invalidEmailMsg;
      } else {
        delete this.formValidationErrors['email'];
      }
    },

    cpfValidate(strCPF) {
        const result = helpers.validarCPF(strCPF);

        if(result) {
            delete this.formValidationErrors['cpf'];
        } else {
            this.formValidationErrors['cpf'] = this.invalidCpfMsg;
        }
    },

    passwordValidate(value) {
      if(value.length < 8 || value.length > 16) {
        this.formValidationErrors['password'] = this.invalidPasswordMsg;

        return false;
      } else {
        delete this.formValidationErrors['password'];
      }

      const hasAccents = /[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/.test(value);

      if(hasAccents) {
        this.formValidationErrors['password'] = this.invalidPasswordMsg;

        return false;
      } else {
        delete this.formValidationErrors['password'];
      }

      const regLower = /[a-z]+/.test(value);
      const regUpper = /[A-Z]+/.test(value);
      const regNumber = /\d+/.test(value);
      const regSpecial = /[#?!@$%^&*-]+/.test(value);

      const passwordRules = [
        regLower && regUpper,
        regLower && regNumber,
        regLower && regSpecial,
        regUpper && regNumber,
        regNumber && regSpecial,
        regUpper && regSpecial,
        regLower && regUpper && regNumber && regSpecial,
      ];

      if(passwordRules.includes(true)) {
        delete this.formValidationErrors['password'];
      } else {
        this.formValidationErrors['password'] = this.invalidPasswordMsg;

        return false;
      }
      return true
    },

    passwordConfirmation(str1, str2) {
      if(str1 !== str2) {
        this.formValidationErrors['passwordConfirmation'] = this.invalidPasswordConfirmation;
      } else {
        delete this.formValidationErrors['passwordConfirmation'];
      }
    },

    phoneValidate(phone) {
      phone = this.strClear(phone);

      if(! phone || phone === '') {
        this.formValidationErrors['phone'] = this.phoneMsg;

        return false;
      } else {
        delete this.formValidationErrors['phone'];
      }

      if(! this.equalDigitsValidate(phone)) {
        this.formValidationErrors['phone'] = this.invalidPhoneMsg;

        return false;
      } else {
        delete this.formValidationErrors['phone'];
      }
    },

    strClear(str) {
      return str.replace(/\D/g, '');
    },

    equalDigitsValidate(str) {
      return new Set(str).size > 1;
    },

    arrayEmptyValidate(field, arr) {
        if(!arr || arr.length === 0) {
            this.formValidationErrors[field] = this.requiredFieldMsg;

            return false;
        } else {
            delete this.formValidationErrors[field];
        }
    },

    // Valida se o formulário foi preenchido totalmente e corretamente
    formValidationRun() {
      return ! Object.keys(this.formValidationErrors).length;
    }
  },
}

export const _ = null
