<template>
  <Auth>
    <section class="card-auth">

      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <b-alert
          v-if="alert.show"
          show
          fade
          class="mb-2"
          variant="danger"
      >
        <div class="custom-alert">
              <span>
                <BIconExclamationCircle />
                {{ alert.message }}
              </span>
        </div>
      </b-alert>

      <b-card-title
          class="mt-2 mb-1 font-weight-bold"
          title-tag="h2"
      >
        Verifique seu E-mail
      </b-card-title>
      <b-card-text class="mb-2">
        Acabamos de enviar uma mensagem para sua conta de e-mail:
        <strong>{{ email }}</strong>. Siga os passos descritos lá para recuperar seu acesso.
      </b-card-text>

      <b-card-text class="text-center mt-2">
        Não recebeu um e-mail?
        <b-link :to="{ name: 'auth-forgot-password' }">
          Reenvie
        </b-link>
      </b-card-text>
    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import {
  BIconGoogle,
  BIconExclamationCircle,
  BIconChevronLeft,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  BCardText,
} from 'bootstrap-vue'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { validation } from "@core/mixins/validation/validation";
import { messages } from "@core/mixins/validation/messages";

export default {
  components: {
    Auth,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BForm,
    BAlert,
    BButton,
    BCardText,
    BIconGoogle,
    BIconExclamationCircle,
    BIconChevronLeft,
    ApplicationLogo,
  },
  mixins: [validation, messages],

  props: ['email'],

  data() {
    return {
      verificationCode: '',
      alert: {
        show: false,
        message: '',
        variant: ''
      },

      loadingShow: true,

      disabledButton: true,
      msgButton: 'Verificar',

      formErrors: {},
    }
  },

  methods: {
    emailCodeVerify() {
      if(this.validation()) {
        this.$router.push({ name: 'auth-reset-password' });
      }
    },


    validation() {
      this.strValidate({
        input: this.verificationCode,
        message: this.verificationCodeMsg,
        count: 6,
        field: 'verificationCode'
      });

      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
